export const soups = [
  {
    name: 'Куриный суп',
    price: 380,
    id: 1,
  },
  {
    name: 'Клэм чаудер с морепродуктами',
    price: 680,
    id: 2,
  },
]
